<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="advantages" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Header"
                  v-model="main.intro.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="SubHeader"
                  v-model="main.intro.subheader"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Description"
                  v-model="main.intro.desc"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-card class="mb-3" title="Image *">
                  <div v-if="imgIntroExisting">
                    <a style="display: block;" target="_blank" :href="imgIntroExisting"><img style="max-width: 400px;" :src="imgIntroExisting"></a>
                    <div><va-button small color="danger" @click="deleteImage()">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    @input="delete errors.img"
                    type="gallery"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.img" style="color: red;">{{ this.errors.img[0] }}</div>
                  <va-button @click="uploadVideo(img[0])">Завантажити</va-button>
                </va-card>
                <va-card class="mb-3" title="Video *">
                  <div v-if="videoExisting">
                    <a style="display: block;" target="_blank" :href="videoExisting">{{videoExisting}}</a>
                    <div><va-button small color="danger" @click="deleteVideo()">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове відео</span>
                  <va-file-upload
                    @input="delete errors.videoSrc"
                    type="gallery"
                    dropzone
                    v-model="video"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.video" style="color: red;">{{ this.errors.video[0] }}</div>
                  <va-button @click="uploadImage(video[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Video"
                  v-model="main.intro.videoSrc"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="linkHref"
                  v-model="main.intro.linkHref"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="linkName"
                  v-model="main.intro.linkName"
                  :messages="['The recommended number of characters is 15']"
                />

              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Header"
                  v-model="main.info.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Description"
                  v-model="main.info.desc"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="linkHref"
                  v-model="main.info.linkHref"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="linkName"
                  v-model="main.info.linkName"
                  :messages="['The recommended number of characters is 15']"
                />

              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Advantages Header"
                  v-model="main.advantages.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Principles Header"
                  v-model="main.principles.header"
                  :messages="['The recommended number of characters is 15']"
                />

              </div>
              <va-button @click="submit()">Save</va-button>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
  },
  data () {
    return {
      main: {
        intro: {
          header: null,
          subheader: null,
          desc: null,
          linkHref: null,
          linkName: null,
          videoSrc: null,
          image: null,
        },
        info: {
          header: null,
          desc: null,
          linkHref: null,
          linkName: null,
        },
        advantages: {
          header: null,
        },
        principles: {
          header: null,
        },
      },

      errors: [],

      imgIntroExisting: null,
      imageNew: null,
      img: [],

      videoExisting: null,
      videoNew: null,
      video: [],

      tabTitles: ['Main', 'Info', 'Other'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    uploadImage (file) {
      console.log(file)
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.imageNew = response.data.patch
          this.imgId = response.data.id
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage () {
      this.imgIntroExisting = ''
      this.imageNew = null
    },
    uploadVideo (file) {
      console.log(file)
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.videoNew = response.data.patch
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteVideo () {
      this.videoExisting = ''
      this.videoNew = null
    },
    submit () {
      this.main.intro.image = this.imageNew
      const data = {
        main: this.main,
      }
      axios.put(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/pages/home`, data)
        .then(response => {
          this.$router.push({ name: 'bgk-page-home' })
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/pages/home`)
        .then(response => {
          this.main = response.data.main
          this.imgIntroExisting = process.env.VUE_APP_BGK_URL + response.data.main.intro.image
          this.imageNew = response.data.main.intro.image
          this.videoExisting = process.env.VUE_APP_BGK_URL + response.data.main.intro.videoSrc
          this.videoNew = response.data.main.intro.videoSrc
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
